<template>
  <div class="volver-container">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      class="volver mb-2"
      block
      @click="$router.go(-1)"
    >
      Volver
    </b-button>
    <H1>Picking {{ splitedPedido(pedidoId) }}</H1>
    <b-row class="blog-list-wrapper">
      <b-col
        v-for="producto in listaProductosPendientes"
        :key="producto.ref"
        md="4"
        sm="6"
        xs="12"
      >
        <b-card
          border-variant="primary"
          bg-variant="transparent"
        >
          <b-row class="mx-0">
            <b-col
              cols="10"
            >
              <b-card-body>
                <b-card-title>
                  {{ producto.ref }}
                </b-card-title>
                <b-media no-body>
                  <b-media-body>
                    <small>
                      {{ producto.name }}
                    </small>
                  </b-media-body>
                </b-media>
                <b-media no-body>
                  <b-media-body>
                    <!-- <small class="text-muted">Faltan: <span class="text-danger">{{ producto.udsPending }}</span> Unidades</small> -->
                    <small>Cargados: {{ producto.udsAdded }} / {{ producto.uds }}</small>
                  </b-media-body>
                </b-media>
              </b-card-body>
            </b-col>
            <b-col
              cols="2"
              class="text-center text-muted"
            >
              <span class="text-danger">{{ producto.udsPending }}</span>
              <!-- {{ producto.udsAdded }}<br>
              de<br>
              {{ producto.uds }} -->
            </b-col>
          </b-row>
          <b-row>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              :disabled="disableAdding"
              @click="addProducto(producto.ref, pedidoId)"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-0"
              />
              <span class="align-middle">1 <span class="ml-2">Agregar</span></span>
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- <pre>{{ listaProductosPendientes }}</pre> -->
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { computed, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import usePicking from './usePicking'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    // const listaProductosPendientes = ref(null)
    const {
      splitedPedido,
      // checkPedido,
      listaProductosPendientes,
      disableAdding,
      addProducto,
      updatePedido,
    } = usePicking()

    const { route } = useRouter()

    const pedidoId = computed(() => route.value.params.id)

    onMounted(() => {
      // console.log(pedidoId.value)
      // listaProductosPendientes.value = checkPedido(pedidoId.value)
      updatePedido(pedidoId.value)
    })

    return {
      splitedPedido,
      pedidoId,
      listaProductosPendientes,
      disableAdding,
      addProducto,
    }
  },
}
</script>
